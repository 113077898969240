import "yet-another-react-lightbox/styles.css";
import React from "react";

import {synths} from "content/synths";
import {Synth} from "./synth/synth";
import {MetaInfo} from "components/meta-info";

export const Warthog = () =>
    {
        const synth = synths[1];
        return <>
        <MetaInfo
            title="Adam Synths - Warthog - Analog Modular Synthesizer - Handcrafted in Valencia, Spain"
            description={synth.description[0].description}
            image={synth.bigImage}
            imageW={1920}
            imageH={1080}
            path="/"
        />
        <Synth synth={synth}/>
        {/* <div onClick={purgeOrder} style={{opacity: 0.2}}>clear</div>*/}
        </>;
    }
