import prima1 from "images/prima/prima1.jpg";
import prima2 from "images/prima/prima2.jpg";
import prima3 from "images/prima/prima3.jpg";
import prima4 from "images/prima/prima4.jpg";
import prima5 from "images/prima/prima5.jpg";

import prima1Big from "images/prima/prima1-big.jpg";
import prima2Big from "images/prima/prima2-big.jpg";
import prima3Big from "images/prima/prima3-big.jpg";
import prima4Big from "images/prima/prima4-big.jpg";
import prima5Big from "images/prima/prima5-big.jpg";

import warthog1 from "images/warthog/warthog1.jpg";
import warthog2 from "images/warthog/warthog2.jpg";
import warthog3 from "images/warthog/warthog3.jpg";
import warthog4 from "images/warthog/warthog4.jpg";
import warthog5 from "images/warthog/warthog5.jpg";
import warthog6 from "images/warthog/warthog6.jpg";
import warthog7 from "images/warthog/warthog7.jpg";
import warthog8 from "images/warthog/warthog8.jpg";
import warthog9 from "images/warthog/warthog9.jpg";
import warthog10 from "images/warthog/warthog10.jpg";
import warthog11 from "images/warthog/warthog11.jpg";
import warthog12 from "images/warthog/warthog12.jpg";

import warthog1Big from "images/warthog/warthog1-big.jpg";
import warthog2Big from "images/warthog/warthog2-big.jpg";
import warthog3Big from "images/warthog/warthog3-big.jpg";
import warthog4Big from "images/warthog/warthog4-big.jpg";
import warthog5Big from "images/warthog/warthog5-big.jpg";
import warthog6Big from "images/warthog/warthog6-big.jpg";
import warthog7Big from "images/warthog/warthog7-big.jpg";
import warthog8Big from "images/warthog/warthog8-big.jpg";
import warthog9Big from "images/warthog/warthog9-big.jpg";
import warthog10Big from "images/warthog/warthog10-big.jpg";
import warthog11Big from "images/warthog/warthog11-big.jpg";
import warthog12Big from "images/warthog/warthog12-big.jpg";

export type Description = {
    title?: string;
    description: string;
}

export type SynthInfo = {
    title: string;
    motto: string;
    model: string
    modelNr: string
    description: Description[];
    images: string[];
    bigImages: string[];
    bigImage: string;
    price: number;
    renderIngredients: boolean;
    renderOrder: boolean;
    footer?: string[];
}


export const synths: Array<SynthInfo> = [
    {
        title: "Meet the Prima!",
        motto: "Pure analog goodness as it's best!\nSeven of our high quality modules.",
        model: "Prima",
        modelNr: "PRIMA",
        description: [
            {
                title: "Modular System",
                description:
                    "Introducing Prima, our meticulously engineered pre-assembled modular system! With our proprietary bus, it comes pre-configured and primed for immediate use straight out of the box. Each front-board I/O has a counterpart on the back panel, and in Prima many are already interconnected in a sensible configuration."
            },
            {
                title: "Normalized IO",
                description: "These connections are made in a [normalized manner](https://en.wikipedia.org/wiki/Patch_panel), but if you simply plug a patch cable into the corresponding front-panel IO, you can override these connections as needed.",
            },
            {
                title: "High Quality Components",
                description: "We prioritize the use of top-notch components in crafting our devices, recognizing that quality materials are fundamental to exceptional performance. That's why Prima is crafted from carefully selected parts that meet the highest standards in both audio circuits and controls.",
            },
            {
                title: "Made In The EU",
                description: "Prima's circuits, designed in Hungary, showcase cutting-edge engineering. Assembled in Spain with precision, each component of the Prima is brought together to form a harmonious whole. Holding the Prima, you experience European ingenuity, a masterpiece of cross-border collaboration."
            },
            {
                title: "Built From Scratch",
                description:
                    "Prima's circuits originated on a blank canvas to become a thoroughly modern instrument constructed entirely from circuits conceived and designed by Adam Synths. Holding the Prima, you're not grasping a reinterpretation or duplicate, but an OG."
            }
        ],
        bigImage: prima1,
        images: [prima2, prima3, prima4, prima5],
        bigImages: [prima1Big, prima2Big, prima3Big, prima4Big, prima5Big],
        price: 2100,
        renderIngredients: true,
        renderOrder: true,        
    },
    {
        title: "Meet the Warthog!",
        motto: "AdamSynths created a brand new modular synthesizer with TOTAL RECALL and DAW integration called “Warthog”.",
        model: "Warthog",
        modelNr: "WARTHOG",
        description: [
            {
                title: "Recall",
                description:
                    "Doesn’t matter if your inspiration fades away, you’ll continue another time. The Warthog is the one and only modular synthesizer in the world which will be your partner in this. Save your patches and simply recall them anytime! With the Warthog you can continue your creation from where you left off last time.",
            },
            {
                description: "The Warthog saves and stores the position of all the potentiometers, of all the switches and modes, and the position of all the patch cables, (which one was plugged in where) as well.",
            },
            {
                title: "Snapshots",
                description: "Create snapshots, which are able to change the sound of the Warthog in a blink of an eye. There’s more than just shifting in between snapshots, but morphing too, even by using the Moodwheels, so you can shift your artistic expression to the next, more colourful level.",
            },
            {
                title: "Downloading and sharing patches, education",
                description: "Share your patches, or either download ones created by others. Cable it for your taste, change it, or use it as it is. Soon enough you’ll be able to download tons of patches here on our website as well.",
            },
            {
                title: "DAW Integration",
                description:
                    "You can integrate the Warthog into any kind of DAW or hardware sequencer. All parameters of our Warthog are controllable real time on USB or MIDI, without using additional patch cables, or occupying more CV in- / outlets.",
            },
            {
                title: "System configurability",
                description: "Configure the system the way you want. The basic model of the Warthog (3x oscillator, 2x multimode VCF & VCA, 1x matrix 428, 1x modulation tools, 2x Dual VC Env, 1x Mood Wheels, 1x central unit, 1x passive divider, 1x audio mixer, case & PSU) is a recommendation, but we can put together the instrument as you wish, either you have a smaller or a larger system in mind.",
            },
            {
                title: "Compatibility",
                description: "Our Warthog is completely compatible with all the standard modular synthesizers. It uses the same V/OCT standard, knows PPQN sync, or TTL level GATE, TRIG and SYNC signals. On the PATCH cables there are the same signals present as on any other modular synthesizers.",
            },
            {
                title: "How the system works",
                description: "All the modules of the Warthog are supervised by the Central Unit. It is responsible for all the recall and interface tasks, and for the complete control and supervision of the modules. This is how a completely controllable and programmable modular synthesizer is built. Thanks to this feature there are no limits of which parameters we’d like to move in real time, nor what kind of specific tasks we’d like to build into the Central Unit in the future.",
            },
            {
                title: "And here comes the future",
                description: "This determines that we have the possibility to further develop the Warthog’s control system in the future so that it can operate in accordance with a module designed for a specific purpose as well. This can also be done on the basis of the user’s requirements.",
            },
            {
                description: "*For the first 50 customers who purchase a Warthog we give a lifetime guaranteed free software upgrade access. But of course, all the firmware upgrades for all the modules will be available in the future for every customer of ours.*",
            },
            {
                description: "To come to the point, the future expansion of the Warthog is not limited to integrating newer types of Adamsynths modules."
            }
        ],
        bigImage: warthog1,
        images: [warthog2, warthog3, warthog4, warthog5, warthog6, warthog7, warthog8, warthog9, warthog10, warthog11, warthog12],
        bigImages: [warthog1Big, warthog2Big, warthog3Big, warthog4Big, warthog5Big, warthog6Big, warthog7Big, warthog8Big, warthog9Big, warthog10Big, warthog11Big, warthog12Big],
        price: 2100,
        renderIngredients: false,
        renderOrder: false,
        footer: ["Have a Warthog! Enjoy the recall!", "Join us and let’s shape together the future of modular synthesizers."],
    },
];
