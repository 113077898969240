import React, { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Zoom } from "yet-another-react-lightbox/plugins";

import { FadeIn } from "components/fade-in";
import { Relative, Clickable, Description, InfoTop, InfoBottom, MainTitle } from "components/styles";
import { useDelayedFadeIn } from "utils/animation-delay";
import { BasePage } from "pages/base-page";
import { SynthInfo } from "content/synths";
import { SynthRow } from "./synth-row";

import { ProgressiveImage } from "components/progressive-image";
import { Ingredients } from "./ingredients";
import { SynthOrder } from "./synth-order";
import { MarkdownRenderer } from "utils/markdown-renderer";

type Props = {
    synth: SynthInfo;
}

export const Synth = ({ synth }: Props) => {
    const { images, bigImage, bigImages, title, motto, description, model } = synth;
    const { nextDelay } = useDelayedFadeIn({});
    const [isGalleryOpen, setIsGalleryOpen] = useState(-1);
    const openGallery = useCallback((index: number) => () => setIsGalleryOpen(index), []);

    return <>
        <Lightbox
            open={isGalleryOpen > -1}
            close={() => setIsGalleryOpen(-1)}
            index={isGalleryOpen}
            slides={bigImages.map(i => ({ src: i }))}
            plugins={[Zoom]}
        />
        <BasePage>
            <Clickable onClick={openGallery(0)}>
                <Relative>
                    <FadeIn nextDelay={nextDelay}>
                        <ProgressiveImage src={bigImage} aspectRatio={1240 / 698} title={`Adam Synths - ${model}`} />
                    </FadeIn>
                    <div className="desktop-only">
                        <InfoTop>
                            <FadeIn nextDelay={nextDelay}>
                                <MainTitle>{title}</MainTitle>
                            </FadeIn>
                        </InfoTop>
                        <InfoBottom>
                            <FadeIn nextDelay={nextDelay}>
                                <Description>
                                    {motto}
                                </Description>
                            </FadeIn>
                        </InfoBottom>
                    </div>
                </Relative>
                <div className="desktop-only mb-5">
                </div>
            </Clickable>
            <Container>
                <div className="mobile-only mb-5">
                    <FadeIn nextDelay={nextDelay}>
                        <MainTitle className="mb-2 mt-4">{`${title}`}</MainTitle>
                    </FadeIn>
                    <FadeIn nextDelay={nextDelay}>
                        <Description>
                            <MarkdownRenderer markdown={motto} />
                        </Description>
                    </FadeIn>
                </div>
                {description.map((h, i) =>
                    <SynthRow
                        key={i}
                        image={images[i % images.length]}
                        description={description[i]}
                        fadeDelay={i}
                        openGallery={openGallery((i + 1) % images.length)}
                        isRight={i % 2 === 0}
                    />)}
                {synth.renderIngredients && <Ingredients />}
                {synth.renderOrder && <SynthOrder synth={synth} />}
                {synth.footer && synth.footer.map((f, i) => <h3 key={i} className="text-center">{f}</h3>)}
            </Container>
        </BasePage>
    </>
}
